var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", [
        _c("div", { staticClass: "grid grid-rows-1" }, [
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c("div", { staticClass: "float-left text-sm" }, [
              _c("label", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.$t("valor")) + ":"),
              ]),
              _c("label", [
                _vm._v(
                  " (" +
                    _vm._s(
                      _vm.formatFloat(
                        _vm._.get(_vm.completeQuestion, "data.score", 0.0),
                        2
                      )
                    ) +
                    ") "
                ),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          [
            _vm.completeQuestion &&
            _vm.completeQuestion.type === "multiple_choice"
              ? _c("multiple-choice-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
            _vm.completeQuestion && _vm.completeQuestion.type === "check_box"
              ? _c("check-box-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
            _vm.completeQuestion && _vm.completeQuestion.type === "simple_text"
              ? _c("simple-text-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
            _vm.completeQuestion && _vm.completeQuestion.type === "gap"
              ? _c("gap-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
            _vm.completeQuestion && _vm.completeQuestion.type === "essay"
              ? _c("essay-show", {
                  key: _vm.key,
                  attrs: { item: _vm.completeQuestion },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-wrap float-right" },
        [
          _c("vs-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top",
                value: {
                  content: _vm.$t("marcar-questao-como-correta"),
                  delay: { show: 1000, hide: 100 },
                },
                expression:
                  "{\n        content: $t('marcar-questao-como-correta'),\n        delay: { show: 1000, hide: 100 }\n      }",
                modifiers: { top: true },
              },
            ],
            staticClass: "m-1",
            attrs: {
              type: "filled",
              icon: "check",
              disabled: _vm.disableCorrectedButton(_vm.completeQuestion),
            },
            on: {
              click: function ($event) {
                return _vm.correctQuestion(true, _vm.completeQuestion)
              },
            },
          }),
          _c("vs-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top",
                value: {
                  content: _vm.$t("marcar-questao-como-incorreta"),
                  delay: { show: 1000, hide: 100 },
                },
                expression:
                  "{\n        content: $t('marcar-questao-como-incorreta'),\n        delay: { show: 1000, hide: 100 }\n      }",
                modifiers: { top: true },
              },
            ],
            staticClass: "m-1",
            attrs: {
              type: "filled",
              color: "danger",
              icon: "clear",
              disabled: _vm.disableWrongButton(_vm.completeQuestion),
            },
            on: {
              click: function ($event) {
                return _vm.correctQuestion(false, _vm.completeQuestion)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }