var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.component !== null
      ? _c(
          "div",
          [
            _c(_vm.component, {
              tag: "component",
              attrs: { item: _vm.data, "brief-mode": true },
            }),
          ],
          1
        )
      : _c("div", {
          directives: [
            {
              name: "html-safe",
              rawName: "v-html-safe",
              value: _vm.buildAnswerHtml(_vm.data, _vm.i),
              expression: "buildAnswerHtml(data, i)",
            },
          ],
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }